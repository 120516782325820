import React from "react"
import { graphql } from "gatsby"
import dayjs from "dayjs"

import {
  BulletListWithIcon,
  HeaderCentered,
  HeaderCenteredIntroText,
  ImageSection,
  Layout,
  Seo,
} from "../components/Shared"

import { BoilerRepairs } from "../components/Sections"

const CovidResponse = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url + "/covid-response"}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <HeaderCentered heading={data.headerAndSeo.headerTitle} />
    <HeaderCenteredIntroText>
      <p>Last updated: {dayjs(data.section.updatedAt).format("DD/MM/YYYY")}</p>
    </HeaderCenteredIntroText>
    <ImageSection
      image={data.section.image.fluid}
      imageDescription={data.section.image.title}
    >
      <h2 className="heading-large">{data.section.heading}</h2>
      <p>{data.section.paragraph.paragraph}</p>
      <BulletListWithIcon bullets={data.section.bullets} />
      <p>Together, we can stay safe.</p>
      <p>
        Unsure about something?{" "}
        <a href="tel: 0191 406 0888">Call us on 0191 406 0888</a>
      </p>
    </ImageSection>
    <BoilerRepairs backgroundColour="grey-lightest" />
  </Layout>
)

export default CovidResponse

export const pageQuery = graphql`
  query COVIDResponseContent {
    site {
      siteMetadata {
        url
      }
    }
    section: contentfulPageSections(
      contentful_id: { eq: "fRgwEqTNUXMAJ1DqDsQD6" }
    ) {
      contentful_id
      image {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
        title
      }
      bullets
      heading
      paragraph {
        paragraph
      }
      updatedAt
    }
    headerAndSeo: contentfulPages(page: { eq: "COVID-19" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
    }
  }
`
